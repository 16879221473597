export const buildAuthHeader = (isFromLocal=true, overrides= {}) => {
    if(isFromLocal){
        const user_token = localStorage.getItem('idi_a');
        const user_id_token = localStorage.getItem('idi_i');
        if(user_token && user_id_token){
            return {
                'Authorization': 'Bearer ' + user_token,
                'idi_i': user_id_token
            }
        }
        return {};
    }
}

export const logOut = () => {
    localStorage.removeItem("idi_i");
    localStorage.removeItem("idi_a");
}

export const doLogin = (access_token, id_token) => {
    localStorage.setItem('idi_i',id_token)
    localStorage.setItem('idi_a',access_token)
}

export const isLoggedIn = () => {
    return localStorage.getItem('idi_a') && localStorage.getItem('idi_i');
}

export const redirectToLogin = () => {
    window.location.href = process.env.REACT_APP_LOGIN_LOCATION + process.env.REACT_APP_LOGIN_REDIRECT_URL;
}

export const redrirectIfUnautherized = () =>{
    if(!isLoggedIn()) {
        redirectToLogin();
    }
}
