import React from "react";
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Login from "./components/login";
import Home from "./components/home";
import {isLoggedIn, redirectToLogin} from "./services/HTTP/AuthService";



function App() {
    const token = localStorage.getItem('idi_a') ? localStorage.getItem('idi_a') : null;
    // if(!initial_token && router())
    const location = window.location.pathname
    console.log(token);
    if(location !== '/login' && !isLoggedIn()){
        redirectToLogin();
    }
    return (
        <div className="App">
            <Router>
                <div>
                    {/*<h2>Welcome to React Router Tutorial</h2>*/}
                    {/*<nav className="navbar navbar-expand-lg navbar-light bg-light">*/}
                    {/*  <ul className="navbar-nav mr-auto">*/}
                    {/*    <li><Link to={'/'} className="nav-link"> Home </Link></li>*/}
                    {/*    <li><Link to={'/contact'} className="nav-link">Contact</Link></li>*/}
                    {/*    <li><Link to={'/about'} className="nav-link">About</Link></li>*/}
                    {/*  </ul>*/}
                    {/*</nav>*/}
                    {/*<hr />*/}
                    <Switch>
                        <Route exact path='/' component={Home}/>
                        <Route exact path='/login' component={Login}/>
                        <Route path='/remotelogin' component={() => {
                            window.location.href = 'https://affilomania.auth.eu-west-1.amazoncognito.com/login';
                            return null;
                        }}/>
                        {/*<Route path='/contact' component={Contact} />*/}
                        {/*<Route path='/about' component={About} />*/}
                    </Switch>
                </div>
            </Router>
            {/*<header className="App-header">*/}
            {/*  <img src={logo} className="App-logo" alt="logo" />*/}
            {/*  <p>*/}
            {/*    Edit <code>src/App.js</code> and save to reload.*/}
            {/*  </p>*/}
            {/*  <a*/}
            {/*    className="App-link"*/}
            {/*    href="https://reactjs.org"*/}
            {/*    target="_blank"*/}
            {/*    rel="noopener noreferrer"*/}
            {/*  >*/}
            {/*    Learn React*/}
            {/*  </a>*/}
            {/*</header>*/}
        </div>
    );
}

export default App;
