import axios from "axios";
import {buildAuthHeader} from "./AuthService";

const sendJson = (url, success_callback, error_callback, data={}, headers={}, requireAuth=false) => {
    // POST request using axios with set headers
    console.log('axios-urk');
    console.log(url)
    console.log('xios')
    console.log(data)
    if(requireAuth){
        headers =  {...buildAuthHeader(), ...headers}
    }
    console.log(process.env.REACT_APP_API_BASE_URL + url);
    axios.post(process.env.REACT_APP_API_BASE_URL + url, data, { headers })
        .then(response => {
                success_callback(response);
            }
        )
        .catch(error => {
            error_callback(error);
        });
}
export default sendJson;