import React, {Fragment, useState, useEffect} from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import {useHistory} from "react-router";
import {doLogin} from "../../services/HTTP/AuthService";



const Login = (props) => {
    const initial_token = localStorage.getItem('idt_i') ? localStorage.getItem('idt_i') : null;
    const [token, setToken] = useState(initial_token)
    const location = useLocation()
    const history = useHistory()
    useEffect(() => {
        const queryParams = new URLSearchParams(location.hash)
        const token_data = queryParams.get('#id_token')
        const access_data = queryParams.get('access_token')
        const ttl = queryParams.get('ttl')
        queryParams.delete('token')
        history.replace({
            search: queryParams.toString()
        })
        if(!token_data){
            console.log('notoken')
        } else{
            doLogin(access_data,token_data)
            setToken(token_data)
        }
    }, [])
    return (
        <Redirect to={'/'} />
    );
}

export default Login
