import React, {useState, useEffect} from 'react';
import sendJson from "../../services/HTTP/post";

const RelatedDomains = ({related_domain}) => {
    const [relatedDomain, setRelatedDomain] = useState(related_domain)

    useEffect(()=>{
       setRelatedDomain(related_domain)
    },[]);
    return(
        <li key={'related_'+related_domain}>{relatedDomain}</li>
    )
}

const AckBtn = ({message_id, exec_function, disabled}) => {
    const clickFunc = () =>{
        exec_function()
    }
    return(
        <button key={'btn_'+message_id} onClick={clickFunc} disabled={disabled}>Ack</button>
    )
}



const Message = (message_object, refresh_function, freeze_function) => {
    const [relatedDomainsList, setRelatedDomainsList] = useState([]);
    const message = message_object.message_object
    const [isSendDisabled, setIsSendDisabled] = useState(false);

    const handleSubmitSucess = () => {
        console.log(4)
    }

    const handleSubmitError = () => {
        setIsSendDisabled(false);
    }

    const sendAck = () => {
        sendJson(process.env.REACT_APP_API_BASE + process.env.REACT_APP_API_VERIOSN + process.env.REACT_APP_ALERTS_ACK,
            handleSubmitSucess, handleSubmitError, {'message_id': message.id}, {}, true)
    }

    const messageSend = () => {
        setIsSendDisabled(true);
        sendAck();
    }

    useEffect(() => {
        const domains_to_add = [];
        const related_domains = JSON.parse(message.related_domains);
        if (Object.keys(related_domains).length > 0) {
            for (const related in related_domains) {
                if (related_domains.hasOwnProperty(related)) {
                    domains_to_add.push(related_domains[related]);
                }
            }
        } else {
            domains_to_add.push('No available domains!')
        }
        setRelatedDomainsList(domains_to_add);
    }, []);


    return (
        <div className="col-sm-12" key={message.id}>
            <h3>{message.content}</h3>
            <p>Related_domains</p>
            {relatedDomainsList.map(function (name, index) {
                return <RelatedDomains key={'rel_l_'+index} related_domain={name}/>;
            })}
            <br/>
            <p><AckBtn key={'btn_' + message.id} message_id={message.id} exec_function={messageSend} disabled={isSendDisabled}/></p>
        </div>
    )
};
export default Message