import React, {Fragment, useState, useEffect} from 'react';
import getJsonWithAuth from "../../services/HTTP/get";
import Message from "./MessageComponant";

const Home = (props) => {
    const [alerts, setAlerts] = useState([])
    const getAlertsLoadSuccess = (data) => {
        console.log('cus')
        if ('alerts' in data){
            console.log('oop')
            const alerts_element = []
            for(const item in data.alerts){
                alerts_element.push(data.alerts[item]);
            }
            setAlerts(alerts_element)
        }
        console.log(data)
    }

    const handleAlertsLoadError = (error) => {
        console.log('err')
        console.log(error)
    }

    const getAlerts = () => {
        getJsonWithAuth(process.env.REACT_APP_API_BASE + process.env.REACT_APP_API_VERIOSN+ process.env.REACT_APP_ALERTS_LIST,
            getAlertsLoadSuccess,handleAlertsLoadError,true)
    }

    useEffect(() => {
        getAlerts();
    }, [])

    return (

        <Fragment>
            <div className="jumbotron text-center">
                <h1>Open Issues</h1>
                <p>Please ACK all handled or you will keep reciveing notifications!</p>
            </div>
            <div className="container">

                <div className="row">
                    {alerts.map(function (message_item, index) {
                        return <Message key={'idi_'+index} message_object={message_item} refresh_function={() => {}} freeze_function={(should_freeze) => {console.log(should_freeze)}} />;
                    })}
                    {/*<div className="col-sm-12">*/}
                    {/*    <h3>Column 1</h3>*/}
                    {/*    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</p>*/}
                    {/*    <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris...</p>*/}
                    {/*</div>*/}
                    {/*<div className="col-sm-12">*/}
                    {/*    <h3>Column 2</h3>*/}
                    {/*    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</p>*/}
                    {/*    <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris...</p>*/}
                    {/*</div>*/}
                    {/*<div className="col-sm-12">*/}
                    {/*    <h3>Column 3</h3>*/}
                    {/*    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</p>*/}
                    {/*    <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris...</p>*/}
                    {/*</div>*/}
                </div>
            </div>
        </Fragment>
    );
}

export default Home
