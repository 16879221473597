import axios from "axios";
import {buildAuthHeader, logOut, redrirectIfUnautherized} from "./AuthService";

const getJsonWithAuth = (url, success_callback, error_callback, requireAuth=false, headers={}, ) => {
    // POST request using axios with set headers
    if(requireAuth){
        headers =  {...buildAuthHeader(), ...headers}
    }
    axios.get(process.env.REACT_APP_API_BASE_URL + url, { headers })
        .then(response => {
                success_callback(response.data);
            }
        )
        .catch(error => {
            if(error.response){
                if(error.response.status === 409){
                    logOut();
                    redrirectIfUnautherized()
                }
                error_callback(error);
            } if (error.request) {
                error_callback('network issue, please check your internet connection!')
            } else {
                error_callback('an error accured please contact support!')
            }

        });
}
export default getJsonWithAuth;